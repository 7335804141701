import { CompleteLayout } from '../../components/forms/CompleteLayout';
import { HeadInner } from '../../components/Head';
import { Layout } from '../../components/layout';

export const Head = () => (
  <HeadInner
    path='/request-document/complete/'
    title='資料請求ありがとうございます'
    noindex
  />
);

const CompletePage = () => {
  const links = [
    { name: '資料ダウンロード', path: '/request-document/' },
    { name: '資料請求を受け付けました', path: '/request-document/complete/' },
  ];

  return (
    <Layout
      header='simple'
      footer='simple'
      background='simple'
      isBottomMenu={false}
      links={links}
    >
      <CompleteLayout>
        <h2>資料請求を受け付けました</h2>
      </CompleteLayout>
    </Layout>
  );
};

export default CompletePage;
